import React from 'react'
import {
  Typography,
  Grid,
  IconButton,
  Button,
  Divider,
  makeStyles,
  Drawer,
  Chip,
  CircularProgress,
  TextField,
  Checkbox,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { Stack } from '@mui/material'
import InlineImageCard from '../InlineCardImage.js'
import apiConfig from '../../apiConfig.js'
import axios from 'axios'
import { unlinkMultiTcin } from '../../store/Workflow/actionCreator.js'
import { useDispatch } from 'react-redux'
import { showNotification } from '../../store/Notification/actionCreator.js'
import { useAuth } from '@praxis/component-auth'
const useStyles = makeStyles((theme) => ({
  multiTcinDrawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '24px',
  },
  multiTcinDrawer: {
    width: '80%',
    maxWidth: '1200px',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#666666',
    margin: 0,
    display: 'flex',
  },

  drawerHeaderDivider: {
    marginBottom: '20px',
    width: `calc(100% + 24px)`,
    marginLeft: '0px',
    '&:before': {
      content: "''",
      width: '34px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    padding: '24px',
  },
  header: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tcinTitles: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  tcinNames: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
  },
  actionButtons: {
    color: '#366CD9',
    borderColor: '#366CD9',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  chipClass: {
    backgroundColor: 'transparent',
    border: '1px solid rgba(25, 118, 210, 0.7)',
    color: 'rgb(25, 118, 210)',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.04)',
    },
  },
  drawerHeaderDividerTCIN: {
    marginBottom: '0 !important',
    width: '100% !important',
  },
}))

export default function EditMultiTcinForm({
  subjectCodesArray = [],
  id = '',
  isOriginal = false,
  originalImage = '',
  isMultiTcin = false,
}) {
  const classes = useStyles()
  const [tcinEditMode, setTcinEditMode] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [editFeaturedTcinList, setEditFeaturedTcinList] = React.useState([])
  const [editSupportingTcinList, setEditSupportingTcinList] = React.useState([])
  const [multiTcinData, setMultiTcinData] = React.useState({})
  const dispatch = useDispatch()
  const auth = useAuth()
  const {
    session: {
      userInfo: { memberOf = [], accessToken = '', lanId = '' } = {},
    } = {},
  } = auth
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    getEditInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const getEditInfo = () => {
    const { editMultiTcinInfo = '' } = apiConfig
    const payloadID = originalImage ? originalImage.split('.')[0] : id
    if (open) {
      setLoading(true)
      const body = {
        tcin: payloadID,
      }
      axios
        .post(`${editMultiTcinInfo}`, body, {
          params: {
            key: apiConfig.apiKey,
          },
        })
        .then((res) => {
          setLoading(false)
          const { data = {} } = res
          setMultiTcinData(data)
        })
        .catch((e) => {
          setLoading(false)
          dispatch(
            showNotification(
              true,
              'Error while getting tcin details',
              'error',
              1000
            )
          )
        })
    }
  }

  const unlinkTcin = (highlighted_image) => {
    setLoading(true)
    dispatch(unlinkMultiTcin(accessToken, highlighted_image, callBack, lanId))
  }
  const callBack = (success = false) => {
    setLoading(false)
    getEditInfo()
    dispatch(
      showNotification(
        true,
        success
          ? 'Tcin Unlinked Successfully'
          : 'An Unknown Error Occured. Please contact support',
        success ? 'success' : 'error',
        3000
      )
    )
  }
  const handleCheckBoxClick = (isChecked, tcinType, tcinDetails) => {
    if (tcinType === 'featured') {
      let newSelected = [...editFeaturedTcinList]
      if (isChecked) {
        newSelected.push(tcinDetails)
      } else {
        const selectedRow = newSelected.findIndex((e) => e === tcinDetails)
        newSelected.splice(selectedRow, 1)
      }
      setEditFeaturedTcinList(newSelected)
    } else {
      let newSelected = [...editSupportingTcinList]
      if (isChecked) {
        newSelected.push(tcinDetails)
      } else {
        const selectedRow = newSelected.findIndex((e) => e === tcinDetails)
        newSelected.splice(selectedRow, 1)
      }
      setEditSupportingTcinList(newSelected)
    }
  }

  const { featured_tcins = [], original = {} } = multiTcinData || {}
  const { thumbnail = [], highlighted_image = '' } = original || {}
  return (
    <>
      <Chip
        variant="outlined"
        color="primary"
        label={`${isOriginal ? 'Multi TCINs' : 'REUSED'} (${
          subjectCodesArray.length
        })`}
        onClick={handleTooltipToggle}
        className={classes.chipClass}
        // style={{ marginRight: 7, marginBottom: 7 }}
      />
      <Drawer
        classes={{
          paper: classes.multiTcinDrawer,
        }}
        anchor="right"
        open={open}
        onClose={handleTooltipClose}
      >
        {loading ? (
          <>
            <div
              style={{
                color: ' rgb(255, 255, 255)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </>
        ) : (
          <>
            <div className={classes.header}>
              <Typography
                component="h1"
                variant="h6"
                align="left"
                className={classes.drawerHeader}
              >
                Edit Multi TCIN Image Metadata for {id} | v1.1
              </Typography>
              <IconButton
                size="small"
                disableFocusRipple
                onClick={handleTooltipClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <>
              <Divider
                className={`${classes.drawerHeaderDivider} ${classes.drawerHeaderDividerTCIN}`}
              />
              <div className={classes.multiTcinDrawerContent}>
                <div style={{ padding: '20px 0' }}>
                  <Grid
                    wrap={'nowrap'}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item lg={2}>
                      <Typography
                        variant="body1"
                        className={classes.tcinTitles}
                      >
                        TCIN
                      </Typography>
                    </Grid>
                    {isOriginal ? (
                      <Grid item lg={10}>
                        {/* <Button
                          disabled={tcinEditMode}
                          onClick={() => setTcinEditMode(!tcinEditMode)}
                          startIcon={<EditIcon color="#366CD9" />}
                          className={classes.actionButtons}
                          variant="outlined"
                        >
                          Edit Featured Supporting TCIN
                        </Button> */}
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    wrap={'nowrap'}
                    style={{ marginTop: '20px' }}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid container alignItems="center" item lg={2}>
                      <Typography variant="body1" className={classes.tcinNames}>
                        {highlighted_image}
                      </Typography>
                    </Grid>
                    <Grid item lg={10}>
                      <InlineImageCard
                        imageList={thumbnail}
                        srcKey="thumbnail_url"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div style={{ padding: '20px 0' }}>
                  <Grid
                    wrap={'nowrap'}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item lg={2}>
                      <Typography
                        variant="body1"
                        className={classes.tcinTitles}
                      >
                        Featured TCINs
                      </Typography>
                    </Grid>
                    {tcinEditMode && (
                      <Grid item lg={10}>
                        <Stack direction="row" gap={2}>
                          <Button
                            startIcon={<AddIcon color="#366CD9" />}
                            className={classes.actionButtons}
                            variant="outlined"
                          >
                            Add Featured TCIN
                          </Button>
                          <Button
                            disabled={!editFeaturedTcinList.length}
                            startIcon={<DeleteForeverIcon color="#366CD9" />}
                            className={classes.actionButtons}
                            variant="outlined"
                          >
                            Delete Featured TCIN
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    wrap="GridWrap"
                    style={{ marginTop: '20px' }}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    {featured_tcins &&
                      featured_tcins.map((dat, i) => {
                        const { thumbnail = [], highlighted_image = '' } = dat
                        const isUnlinkDisabled = thumbnail.filter(
                          (data) =>
                            data?.file_name === `${highlighted_image}.tif`
                        )
                        const isChecked =
                          editFeaturedTcinList.includes(highlighted_image)
                        return (
                          <>
                            <Grid container alignItems="center" item lg={2}>
                              {tcinEditMode ? (
                                <>
                                  <Checkbox
                                    sx={{
                                      '&.MuiSvgIcon-root': { fontSize: 50 },
                                    }}
                                    onClick={() =>
                                      handleCheckBoxClick(
                                        !isChecked,
                                        'featured',
                                        highlighted_image
                                      )
                                    }
                                    checked={isChecked}
                                    style={{
                                      padding: 0,
                                      marginRight: '10px',
                                    }}
                                  />
                                  <TextField
                                    style={{
                                      maxWidth: '120px',
                                      marginBottom: 0,
                                    }}
                                    InputProps={{
                                      style: {
                                        maxHeight: '48px',
                                        fontSize: '14px',
                                      },
                                    }}
                                    variant="outlined"
                                    placeholder="TCIN"
                                    value={84154614}
                                    // onChange={handleInputChange}
                                    name="name"
                                  />
                                </>
                              ) : (
                                <Typography
                                  variant="body1"
                                  className={classes.tcinNames}
                                >
                                  {highlighted_image}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item lg={10}>
                              <InlineImageCard
                                imageList={thumbnail}
                                srcKey="thumbnail_url"
                              />
                              <Button
                                className={classes.actionButtons}
                                variant="outlined"
                                disabled={
                                  isUnlinkDisabled[0]?.unlinked || false
                                }
                                style={{
                                  display:
                                    isOriginal && isMultiTcin
                                      ? ''
                                      : highlighted_image === id
                                      ? ''
                                      : 'none',
                                  marginBottom: '70px',
                                }}
                                onClick={() =>
                                  unlinkTcin(`${highlighted_image}.tif`)
                                }
                              >
                                {isOriginal && isMultiTcin
                                  ? 'Unlink'
                                  : highlighted_image === id
                                  ? 'Unlink from main TCIN'
                                  : ''}
                              </Button>
                            </Grid>
                          </>
                        )
                      })}
                  </Grid>
                </div>
                {/* <div style={{ padding: '20px 0' }}>
                  <Grid
                    wrap={'nowrap'}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item lg={2}>
                      <Typography
                        variant="body1"
                        className={`${classes.tcinTitles}`}
                      >
                        Supporting TCINs
                      </Typography>
                    </Grid>
                    {tcinEditMode && (
                      <Grid item lg={10}>
                        <Stack direction="row" gap={2}>
                          <Button
                            startIcon={<AddIcon color="#366CD9" />}
                            className={classes.actionButtons}
                            variant="outlined"
                          >
                            Add Supporting TCIN
                          </Button>
                          <Button
                            disabled={!editSupportingTcinList.length}
                            startIcon={<DeleteForeverIcon color="#366CD9" />}
                            className={classes.actionButtons}
                            variant="outlined"
                          >
                            Delete Supporting TCIN
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    wrap="GridWrap"
                    style={{ marginTop: '20px' }}
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                  >
                    {[...Array(2)].map((dat, i) => {
                      const isChecked = editSupportingTcinList.includes(
                        `84154614-${i}`
                      )
                      return (
                        <>
                          <Grid container alignItems="center" item lg={2}>
                            {tcinEditMode ? (
                              <>
                                <Checkbox
                                  sx={{
                                    '&.MuiSvgIcon-root': { fontSize: 50 },
                                  }}
                                  onClick={() =>
                                    handleCheckBoxClick(
                                      !isChecked,
                                      'supporting',
                                      `84154614-${i}`
                                    )
                                  }
                                  style={{
                                    padding: 0,
                                    marginRight: '10px',
                                  }}
                                />
                                <TextField
                                  style={{
                                    maxWidth: '120px',
                                    marginBottom: 0,
                                  }}
                                  InputProps={{
                                    style: {
                                      maxHeight: '48px',
                                      fontSize: '14px',
                                    },
                                  }}
                                  variant="outlined"
                                  placeholder="TCIN"
                                  value={84154614}
                                  // onChange={handleInputChange}
                                  name="name"
                                />
                              </>
                            ) : (
                              <Typography
                                variant="body1"
                                className={classes.tcinNames}
                              >
                                84154614
                              </Typography>
                            )}
                          </Grid>
                          <Grid item lg={10}>
                            <InlineImageCard imageList={[...Array(5 + i)]} />
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>
                </div> */}
              </div>
              {tcinEditMode && (
                <div className={classes.buttons}>
                  <Button variant="contained" color="primary">
                    Update Metadate
                  </Button>

                  <Button
                    onClick={() => setTcinEditMode(!tcinEditMode)}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </>
          </>
        )}
      </Drawer>
    </>
  )
}
