import * as React from 'react'
import {
  IconButton,
  Chip,
  ClickAwayListener,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '150px',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '23px',
    flex: '1',
    color: '#333333',
    margin: '0',
  },
  closeIcon: {
    color: '#ffffff',
    padding: '0 !important',
    marginRight: '-9px !important',
  },
}))

export const MultiTcinFlyout = ({
  subjectCodesArray = [],
  subjectCodes = '',
  tcin = '',
  studio = '',
}) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        placement="left-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              background: '#FFFFFF',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              color: '#333333',
              width: '300px',
              padding: '15px 24px',
            },
          },
        }}
        title={
          <div className={classes.tooltipContainer}>
            <Grid
              wrap={'nowrap'}
              container
              justify={'space-between'}
              alignItems={'center'}
              style={{ marginBottom: '15px' }}
            >
              <h4 className={classes.drawerHeader}>{`${tcin} - ${
                studio === 'SchawkAsia' || studio === 'WinterSt'
                  ? 'Multi TCINs'
                  : 'Associated TCINs'
              } (${subjectCodesArray.length})`}</h4>
              <IconButton
                aria-label="close"
                onClick={handleTooltipClose}
                className={classes.closeIcon}
              >
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>{' '}
            </Grid>
            <div
              style={{
                flexGrow: 1,
                padding: '5px 0px',
                overflowY: 'auto',
              }}
            >
              <Typography
                style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: 14 }}
                variant="body2"
              >
                {subjectCodes}
              </Typography>
            </div>
            <div>
              <CopyToClipboard text={subjectCodes}>
                <Button
                  style={{
                    padding: '6px 3px',
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#366CD9',
                  }}
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  Copy To Clipboard
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        }
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
          borderRadius: '4px',
        }}
      >
        <Chip
          variant="outlined"
          color="primary"
          label={`${
            studio === 'SchawkAsia' || studio === 'WinterSt'
              ? 'Multi TCINs'
              : 'Associated TCINs'
          } (${subjectCodesArray.length})`}
          onClick={handleTooltipToggle}
          style={{ marginRight: 7, marginBottom: 7 }}
        />
      </Tooltip>
    </ClickAwayListener>
  )
}
