import PhotoData from './PhotoData'
import ItemData from './ItemData'
import SampleData from './SampleData'

export const itemType = {
  'Variation Parent w/in Collection': 'VPC',
  'Variation Parent': 'VP',
  'Variation Child': 'VC',
  'Collection Parent': 'COP',
  'Collection Child': 'CC',
  'Stand Alone': 'SA',
}

export const tabLabels = [
  { label: 'photo', component: PhotoData },
  { label: 'item', component: ItemData },
  { label: 'sample', component: SampleData },
]

export const columnMap = [
  {
    groupId: 'tcin',
    category: 'item',
    custColName: 'TCIN',
    custColType: 'checkbox',
    selected: true,
    colId: 'tcin',
    colName: 'TCIN',
    colOrder: 0,
    sortable: true,
    data: 'tcin',
    dataType: 'single',
  },
  {
    groupId: 'relationship_type',
    category: 'item',
    custColName: 'Relationship Type',
    custColType: 'checkbox',
    selected: true,
    colId: 'relationship_type',
    colName: 'Relationship Type',
    colOrder: 1,
    sortable: true,
    data: 'item_info.relationship',
    dataType: 'single',
  },
  {
    groupId: 'dpci',
    category: 'item',
    custColName: 'DPCI',
    custColType: 'checkbox',
    selected: true,
    colId: 'dpci',
    colName: 'DPCI',
    colOrder: 2,
    sortable: true,
    data: 'dpci',
    dataType: 'single',
  },
  {
    groupId: 'item_description',
    category: 'item',
    custColName: 'Description',
    custColType: 'checkbox',
    selected: true,
    colId: 'item_description',
    colName: 'Description',
    colOrder: 3,
    sortable: true,
    data: 'item_info.product_description',
    dataType: 'single',
  },
  {
    groupId: 'launch_date',
    category: 'item',
    custColName: 'Launch Date',
    custColType: 'checkbox',
    selected: true,
    colId: 'launch_date_time',
    colName: 'Launch Date',
    colOrder: 4,
    sortable: true,
    data: 'item_info.launch_date_time',
    dataType: 'date',
  },
  {
    groupId: 'parent_tcins',
    category: 'item',
    custColName: 'Parent TCIN',
    custColType: 'checkbox',
    selected: false,
    colId: 'parent_tcins',
    colName: 'Parent TCIN',
    colOrder: 5,
    sortable: true,
    data: 'item_info.parent_tcins',
    dataType: 'single',
  },
  {
    groupId: 'class_data',
    category: 'item',
    custColName: 'Class ID/Name',
    custColType: 'checkbox',
    selected: false,
    colId: 'class_data',
    colName: 'Class ID/Name',
    colOrder: 6,
    sortable: true,
    data: 'item_info.class_id',
    dataType: 'merge',
  },
  {
    groupId: 'department_data',
    category: 'item',
    custColName: 'Department ID/Name',
    custColType: 'checkbox',
    selected: false,
    colId: 'department_data',
    colName: 'Department ID/Name',
    colOrder: 7,
    sortable: true,
    data: 'item_info.department_id',
    dataType: 'merge',
  },
  {
    groupId: 'barcode',
    category: 'item',
    custColName: 'Barcode',
    custColType: 'checkbox',
    selected: false,
    colId: 'barcode',
    colName: 'Barcode',
    colOrder: 8,
    sortable: true,
    data: 'item_info.barcode_number',
    dataType: 'single',
  },
  {
    groupId: 'vendor_id',
    category: 'item',
    custColName: 'Vendor ID',
    custColType: 'checkbox',
    selected: false,
    colId: 'vendor_id',
    colName: 'Vendor ID',
    colOrder: 9,
    sortable: true,
    data: 'item_info.vendor_id',
    dataType: 'single',
  },
  {
    groupId: 'manufacturer_style',
    category: 'item',
    custColName: 'Manufacturer Style',
    custColType: 'checkbox',
    selected: false,
    colId: 'manufacturer_style',
    colName: 'Manufacturer Style',
    colOrder: 10,
    sortable: true,
    data: 'item_info.manufacturer_style',
    dataType: 'single',
  },
  {
    groupId: 'size',
    category: 'item',
    custColName: 'Size',
    custColType: 'checkbox',
    selected: false,
    colId: 'size',
    colName: 'Size',
    colOrder: 11,
    sortable: true,
    data: 'item_info.size',
    dataType: 'single',
  },
  {
    groupId: 'color',
    category: 'item',
    custColName: 'Color',
    custColType: 'checkbox',
    selected: false,
    colId: 'color',
    colName: 'Color',
    colOrder: 12,
    sortable: true,
    data: 'item_info.color',
    dataType: 'single',
  },
  {
    groupId: 'brand_name',
    category: 'item',
    custColName: 'Brand',
    custColType: 'checkbox',
    selected: false,
    colId: 'brands',
    colName: 'Brand',
    colOrder: 13,
    sortable: true,
    data: 'item_info.brands',
    dataType: 'single',
  },
  {
    groupId: 'item_state',
    category: 'item',
    custColName: 'Lifecycle State',
    custColType: 'checkbox',
    selected: false,
    colId: 'item_state',
    colName: 'Lifecycle State',
    colOrder: 14,
    sortable: true,
    data: 'item_info.item_state',
    dataType: 'single',
  },
  {
    groupId: 'sample_ids',
    category: 'sample',
    custColName: 'Sample ID',
    custColType: 'checkbox',
    selected: false,
    colId: 'sample_ids',
    colName: `Sample ID`,
    colOrder: 15,
    sortable: false,
    data: 'sample_info[0].pegasus_id',
    dataType: 'array',
  },
  {
    groupId: 'sample_status',
    category: 'sample',
    custColName: 'Sample Status',
    custColType: 'checkbox',
    selected: false,
    colId: 'sample_status',
    colName: 'Sample Status',
    colOrder: 16,
    sortable: false,
    data: 'sample_info[0].sample_status',
    dataType: 'array',
  },
  {
    groupId: 'sample_ship_location',
    category: 'sample',
    custColName: 'Sample Ship To Location',
    custColType: 'checkbox',
    selected: false,
    colId: 'sample_ship_location',
    colName: 'Sample Ship To Location',
    colOrder: 17,
    sortable: false,
    data: 'sample_info[0].ship_to_loc',
    dataType: 'array',
  },
  {
    groupId: 'sample_received_date',
    category: 'sample',
    custColName: 'Sample Receieved Date',
    custColType: 'checkbox',
    selected: false,
    colId: 'sample_received_date',
    colName: 'Sample Receieved Date',
    colOrder: 18,
    sortable: false,
    data: 'sample_info[0].received_date',
    dataType: 'array',
  },
  {
    groupId: 'sample_current_location',
    category: 'sample',
    custColName: 'Sample Current Location',
    custColType: 'checkbox',
    selected: true,
    colId: 'sample_current_location',
    colName: 'Sample Current Location',
    colOrder: 19,
    sortable: false,
    data: 'sample_info[0].location',
    dataType: 'array',
  },
  {
    groupId: 'sample_projects',
    category: 'sample',
    custColName: 'Sample Project(s)',
    custColType: 'checkbox',
    selected: false,
    colId: 'sample_projects',
    colName: 'Sample Project',
    colOrder: 20,
    sortable: false,
    data: 'sample_info[0].projects',
    dataType: 'array',
  },
  {
    groupId: 'layout',
    category: 'options',
    custColName: 'Nested Layout',
    custColType: 'checkbox',
    selected: false,
    colOrder: -1,
  },
  {
    groupId: 'alt_image',
    isParent: true,
    category: 'options',
    custColName: 'Include Alt Image Info',
    custColType: 'checkbox',
    selected: false,
    colOrder: -2,
  },
  {
    groupId: 'alt_source_image',
    isParent: true,
    category: 'options',
    custColName: 'Include Source Alt Image Info',
    custColType: 'checkbox',
    selected: false,
    colOrder: -3,
  },
  {
    groupId: 'photo_project',
    category: 'photo',
    custColName: 'Photo Project(s)',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'sent_to_pipeline',
    colName: 'Project',
    colOrder: 21,
    sortable: false,
    data: 'project_information.projects',
    dataType: 'project_array',
  },
  {
    groupId: 'scene7_main_img',
    category: 'photo',
    custColName: 'T.com Image',
    custColType: 'checkbox',
    selected: false,
    colId: 'scene7_main_img',
    colName: 'T.com Image',
    colOrder: 22,
    sortable: false,
    data: ['item_info.images.main'],
    dataType: 'image',
  },
  {
    groupId: 'scene7_alt_img',
    category: 'photo',
    custColName: 'Final Alt',
    custColType: 'checkbox',
    selected: false,
    colId: 'scene7_alt_img',
    colName: 'Final Alt',
    colOrder: 23,
    sortable: false,
    data: 'item_info.images.alt',
    dataType: 'image_array',
  },
  {
    groupId: 'image_shots',
    category: 'photo',
    custColName: '#Shots',
    custColType: 'checkbox',
    selected: true,
    colId: 'no_of_shots',
    colName: '#Shots',
    colOrder: 24,
    sortable: true,
    data: 'image_information',
    dataType: 'image_count',
  },
  {
    groupId: 'source_image',
    category: 'photo',
    custColName: 'Source Image',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'source_image',
    colName: 'Source Image',
    colOrder: 25,
    sortable: false,
    data: [
      'image_information.main.source_image.preview_image_url',
      'image_information.main.source_image.zoom_image_url',
    ],
    metaData: 'image_information.main.metadata',
    fileName: 'image_information.main.file_name',
    dataType: 'image',
    layout: 'nested',
  },
  {
    groupId: 'image',
    category: 'photo',
    custColName: 'WIP Image',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'image',
    colName: 'WIP Image',
    colOrder: 26,
    sortable: false,
    data: [
      'image_information.main.metadata.preview_image_url',
      'image_information.main.metadata.zoom_image_url',
    ],
    metaData: 'image_information.main.metadata',
    fileName: 'image_information.main.file_name',
    dataType: 'image',
    layout: 'nested',
  },
  {
    groupId: 'file_name',
    category: 'photo',
    custColName: 'File Name',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'file_name',
    colName: 'File name',
    colOrder: 27,
    sortable: true,
    data: 'image_information.main.file_name',
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_studio',
    category: 'photo',
    custColName: 'Photo Studio',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'photo_studio',
    colName: 'Photo Studio',
    colOrder: 28,
    sortable: true,
    data: 'image_information.main.metadata.photo_studio',
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_shot',
    category: 'photo',
    custColName: 'Photo Shot Date',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'photo_shot',
    colName: 'Photo Shot',
    colOrder: 29,
    sortable: true,
    data: 'image_information.main.metadata.image_created_dt',
    dataType: 'date',
    layout: 'nested',
  },
  {
    groupId: 'photo_tbay',
    category: 'photo',
    custColName: 'TBay',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: false,
    colId: 'photo_tbay',
    colName: 'TBay',
    colOrder: 30,
    sortable: true,
    data: 'image_information.main.metadata.tbay',
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_source',
    category: 'photo',
    custColName: 'Sent To PhotoBridge',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: false,
    colId: 'photo_source',
    colName: 'Sent To PhotoBridge',
    colOrder: 31,
    sortable: true,
    data: 'image_information.main.metadata.date_ingested',
    dataType: 'date',
    layout: 'nested',
  },
  {
    groupId: 'photographer',
    category: 'photo',
    custColName: 'Photographer',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: false,
    colId: 'photographer',
    colName: 'Photographer',
    colOrder: 32,
    sortable: true,
    data: 'image_information.main.metadata.photographer',
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_retouch_status',
    category: 'photo',
    custColName: 'Image Status',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: false,
    colId: 'photo_retouch_status',
    colName: 'Image Status',
    colOrder: 33,
    sortable: true,
    data: 'image_information.main.retouch.retouch_status',
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_pipeline',
    category: 'photo',
    custColName: 'Sent To Pipeline',
    custColType: 'checkbox',
    custColGroup: 'each',
    selected: true,
    colId: 'photo_pipeline',
    colName: 'Sent To Pipeline',
    colOrder: 34,
    sortable: true,
    data: 'image_information.main.retouch.sent_to_pipeline',
    dataType: 'date',
    layout: 'nested',
  },
]

export const altImageData = (index) => [
  {
    groupId: 'image',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'alt_image_' + (index + 1),
    colName: 'WIP Alt Image ' + (index + 1),
    colOrder: 34 + index,
    sortable: false,
    data: [
      `image_information.alt[${index}].metadata.preview_image_url`,
      `image_information.alt[${index}].metadata.zoom_image_url`,
    ],
    dataType: 'image',
    metaData: `image_information.alt[${index}].metadata`,
    fileName: `image_information.alt[${index}].file_name`,
    layout: 'nested',
  },
  {
    groupId: 'file_name',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'file_name_' + (index + 1),
    colName: 'File Name ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].file_name`,
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_studio',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_studio_' + (index + 1),
    colName: 'Photo Studio ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].metadata.photo_studio`,
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_shot',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_shot_' + (index + 1),
    colName: 'Photo Shot Date ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].metadata.image_created_dt`,
    dataType: 'date',
    layout: 'nested',
  },
  {
    groupId: 'photo_tbay',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_tbay_' + (index + 1),
    colName: 'TBay ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].metadata.tbay`,
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_source',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_source_' + (index + 1),
    colName: 'Photo Source Date ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].metadata.date_ingested`,
    dataType: 'date',
    layout: 'nested',
  },
  {
    groupId: 'photographer',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photographer_' + (index + 1),
    colName: 'Photographer ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].metadata.photographer`,
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_retouch_status',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_retouch_status_' + (index + 1),
    colName: 'Image Status ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].retouch.retouch_status`,
    dataType: 'single',
    layout: 'nested',
  },
  {
    groupId: 'photo_pipeline',
    parentId: 'alt_image',
    id: index,
    category: 'photo',
    selected: false,
    colId: 'photo_pipeline_' + (index + 1),
    colName: 'Sent To Pipeline ' + (index + 1),
    colOrder: 34 + index,
    sortable: true,
    data: `image_information.alt[${index}].retouch.sent_to_pipeline`,
    dataType: 'date',
    layout: 'nested',
  },
]
export const altSourceImageData = (index) => {
  return [
    {
      groupId: 'source_image',
      parentId: 'alt_source_image',
      id: index,
      category: 'photo',
      selected: false,
      colId: 'alt_source_image_' + (index + 1),
      colName: 'Source Alt Image ' + (index + 1),
      colOrder: 33 + index,
      sortable: false,
      data: [
        `image_information.alt[${index}].source_image.preview_image_url`,
        `image_information.alt[${index}].source_image.zoom_image_url`,
      ],
      dataType: 'image',
      metaData: `image_information.alt[${index}].metadata`,
      fileName: `image_information.alt[${index}].file_name`,
      layout: 'nested',
    },
  ]
}
