import { Chip, Switch, IconButton } from '@mui/material'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import { Box } from '@mui/system'
import { NotesChipFlyout, NotesIconFlyout } from './noteFlyout'
import ImageNA from '../../images/ImageNA.svg'
import { formatDate, toSafeData } from '../../containers/TcinInfo/mapper'
import cloneDeep from 'lodash/cloneDeep'
import { get, isEmpty, isBoolean } from 'lodash'
import { MultiTcinFlyout } from './MutiTcinFlyout'
import ErrorIcon from '@material-ui/icons/Error'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SentToPipelineFlyout from './SentToPipelineFlyout'
import EditMultiTcinForm from '../../components/EditMutiTcinForm'

const renderChips = (params) => {
  if (params?.length > 0) {
    return params?.map((chip, index) => {
      if (chip.tagName && chip.tagName.trim().length > 0) {
        return (
          <NotesChipFlyout
            key={index}
            index={index}
            tag={chip.tagName}
            notes={chip.tagDesc}
          />
        )
      } else {
        return (
          <Chip
            key={index}
            label={chip.tag}
            size="small"
            style={{ marginRight: 7, marginBottom: 7 }}
          />
        )
      }
    })
  } else {
    return '-'
  }
}

const renderImageStatusChip = (params) => {
  if (params?.imageStatus?.length > 0) {
    return <SentToPipelineFlyout data={params} />
  } else {
    return '-'
  }
}

export const studioAndProcess = [
  // { val: 'All', label: 'All' },
  { val: 'WinterSt', label: 'Winter Street' },
  { val: 'Splashlight', label: 'Splashlight' },
  { val: 'Periscope', label: 'Periscope' },
  { val: 'PeriscopeHK', label: 'Periscope HK' },
  { val: 'IndustrialColor', label: 'Industrial Color' },
  { val: 'Schawk', label: 'Schawk' },
  { val: 'SchawkAsia', label: 'Schawk Asia' },
]

export const pipeLineStatus = [
  'accepted by pipeline',
  'rejected by pipeline',
  'waiting for pipeline',
  'received by pipeline',
  'sent to pipeline',
]

export const pipeLineStatusColours = {
  'photo shot & ingested': { color: '#590DE5' },
  reshoot: {
    color: '#A35C25',
    icon: <ErrorIcon style={{ color: '#A35C25' }} />,
  },
  'ready for retouching': { color: '#9061F6' },
  'retouching complete': { color: '#B388F5' },
  'qa complete': { color: '#D5B4FA' },
  'color correction': { color: '#c6b0f6' },
  'ready for review': { color: '#63D7C5' },
  approved: { color: '#50B0A6' },
  'rejected by pipeline': {
    color: '#A35C25',
    icon: <ErrorIcon style={{ color: '#A35C25' }} />,
  },
  'waiting for pipeline': {
    color: '#E4B73F',
    icon: <HourglassEmptyIcon color="#E4B73F" />,
  },
  'sent to pipeline': { color: '#E4B73F' },
  'received by pipeline': {
    color: '#E4B73F',
    icon: <CheckCircleOutlineIcon style={{ color: '#E4B73F' }} />,
  },
  'accepted by pipeline': {
    color: '#4A8453',
    icon: <CheckCircleIcon style={{ color: '#4A8453' }} />,
  },
  archived: { color: '#999999' },
}

const renderNotesIcon = (params) => {
  if (params && params.trim().length > 0) {
    return <NotesIconFlyout notes={params} />
  } else {
    return (
      <IconButton disabled>
        <StickyNote2Icon color="disabled" />
      </IconButton>
    )
  }
}

const renderTCINIcon = (params) => {
  const {
    subjectCodes = '',
    tcin = '',
    isMultiTcin = false,
    isOriginal = false,
    id = '',
    originalImage = '',
    photoStudio = '',
  } = params
  const subjectCodesArray = subjectCodes
    ?.split(/(?:,|;)+/)
    .filter(
      (dat) =>
        dat.length &&
        !dat.toLowerCase().includes('_p') &&
        !dat.includes('-') &&
        dat !== tcin.toString()
    )

  if (isMultiTcin) {
    return (
      <EditMultiTcinForm
        originalImage={originalImage}
        isOriginal={isOriginal}
        subjectCodesArray={subjectCodesArray}
        id={id}
        isMultiTcin={isMultiTcin}
      />
    )
  } else if (!isMultiTcin && subjectCodes?.length > 0) {
    return (
      <MultiTcinFlyout
        studio={photoStudio}
        subjectCodesArray={subjectCodesArray}
        subjectCodes={subjectCodes}
        tcin={tcin}
      />
    )
  } else {
    return <span>-</span>
  }
}

const renderTextWrapCell = (params) => (
  <Box
    sx={{
      maxHeight: 'inherit',
      width: '100%',
      whiteSpace: 'initial',
      lineHeight: '16px',
    }}
  >
    {params.value}
  </Box>
)

export const workflowTabs = {
  new: 'NEW',
  retouch: 'RETOUCH',
  review: 'REVIEW',
  cc: 'COLOR',
  hold: 'HOLD',
  approved: 'APPROVED',
  search: 'SEARCH',
}

export const columnCategories = {
  workflow: 'Workflow',
  photo: 'Photo',
  item: 'Item',
}

export const workflowColumns = [
  {
    id: 'fileName',
    field: 'fileName',
    headerName: 'Image Name',
    width: 175,
    category: columnCategories.photo,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'image_name',
    alwaysInclude: true,
  },
  {
    id: 'version',
    field: 'version',
    headerName: 'Version',
    width: 80,
    category: columnCategories.photo,
    defaultIn: Object.values(workflowTabs),
    sort_disabled: true,
  },
  {
    id: 'previewImage',
    headerName: 'Image',
    width: 120,
    isImage: true,
    field: ['previewImage', 'zoomImage', 'originalImageUrl'],
    category: columnCategories.photo,
    default: ImageNA,
    notIncludeInCsv: true,
    defaultIn: Object.values(workflowTabs),
    sort_disabled: true,
  },
  {
    id: 'ingestionDate',
    field: 'ingestionDate',
    headerName: 'Ingestion Date',
    width: 120,
    category: columnCategories.photo,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'ingestion_date',
  },
  {
    id: 'photoStudio',
    field: 'photoStudio',
    headerName: 'Photo Studio',
    width: 120,
    category: columnCategories.photo,
    defaultIn: [],
    sort_name: 'photo_studio',
  },
  {
    id: 'photoShotDate',
    field: 'photoShotDate',
    headerName: 'Photo Shot Date',
    width: 130,
    renderCell: (row) => {
      return `${formatDate(toSafeData(row, 'photoShotDate', '-'))}`
    },
    category: columnCategories.photo,
    defaultIn: [],
    sort_name: 'photo_shot_date',
  },
  {
    id: 'tbay',
    field: 'tbay',
    headerName: 'TBay',
    width: 100,
    category: columnCategories.photo,
    defaultIn: [],
    sort_name: 'tbay',
  },
  {
    id: 'fileSize',
    field: 'fileSize',
    headerName: 'File Size',
    width: 100,
    category: columnCategories.photo,
    defaultIn: [],
    sort_disabled: true,
  },
  {
    id: 'photographer',
    field: 'photographer',
    headerName: 'Photographer',
    width: 120,
    category: columnCategories.photo,
    defaultIn: [workflowTabs.new],
    sort_name: 'photographer',
  },
  {
    id: 'stylist',
    field: 'stylist',
    headerName: 'Stylist',
    width: 120,
    category: columnCategories.photo,
    defaultIn: [workflowTabs.new],
    sort_name: 'stylist',
  },
  {
    id: 'altPosition',
    field: 'altPosition',
    headerName: 'Alt Position',
    width: 100,
    category: columnCategories.photo,
    defaultIn: [],
    sort_name: 'alt_position',
  },
  {
    id: 'sentToPipeline',
    field: 'sentToPipeline',
    headerName: 'Sent To Pipeline',
    width: 130,
    renderCell: (row) => {
      return `${formatDate(toSafeData(row, 'sentToPipeline', '-'))}`
    },
    category: columnCategories.photo,
    defaultIn: [],
    sort_name: 'sent_to_pipeline',
  },
  {
    id: 'projects',
    field: 'projects',
    headerName: 'Projects',
    width: 150,
    category: columnCategories.photo,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'project_name',
  },
  {
    id: 'subjectCodes',
    field: 'subjectCodes',
    headerName: 'Multi TCIN',
    width: 200,
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {renderTCINIcon(params)}
        </div>
      )
    },
    category: columnCategories.photo,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'multi_tcin',
  },
  {
    id: 'imageStatus',
    field: 'imageStatus',
    headerName: 'Image Status',
    width: 180,
    category: columnCategories.workflow,
    renderCell: (params) => renderImageStatusChip(params),
    defaultIn: Object.values(workflowTabs),
    alwaysInclude: true,
    sort_name: 'image_status',
  },
  {
    id: 'tags',
    field: 'tags',
    headerName: 'Tags',
    width: 200,
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {renderChips(params.tags)}
        </div>
      )
    },
    category: columnCategories.workflow,
    notIncludeInCsv: true,
    defaultIn: Object.values(workflowTabs),
    sort_disabled: true,
  },
  {
    id: 'notes',
    field: 'notes',
    headerName: 'Notes',
    width: 70,
    renderCell: (params) => renderNotesIcon(params.notes),
    category: columnCategories.workflow,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'notes',
  },
  {
    id: 'qaNotes',
    field: 'qaNotes',
    headerName: 'QA Notes',
    width: 90,
    renderCell: (params) => renderNotesIcon(params.qaNotes),
    category: columnCategories.workflow,
    defaultIn: [],
    sort_disabled: true,
  },
  {
    id: 'assignee',
    field: 'assignee',
    headerName: 'Assignee',
    width: 120,
    category: columnCategories.workflow,
    defaultIn: [
      workflowTabs.retouch,
      workflowTabs.cc,
      workflowTabs.review,
      workflowTabs.approved,
    ],
    sort_name: 'assignee',
  },
  {
    id: 'qaAssignee',
    field: 'qaAssignee',
    headerName: 'QA Assignee',
    width: 120,
    category: columnCategories.workflow,
    defaultIn: [workflowTabs.retouch],
    sort_name: 'qa_assignee',
  },
  {
    id: 'wip',
    field: 'wip',
    headerName: 'WIP',
    width: 80,
    renderCell: (params) => {
      const isInProgress =
        isBoolean(get(params, 'wip')) && !isEmpty(get(params, 'wip'))
      return (
        <Switch
          disabled
          checked={isInProgress}
          color="primary"
          inputProps={{
            'aria-label': `Work is ${!isInProgress && `not`} in Progress`,
          }}
        />
      )
    },
    category: columnCategories.workflow,
    sort_disabled: true,
  },
  {
    id: 'lastUpdatedDate',
    field: 'lastUpdatedDate',
    headerName: 'Last Updated',
    width: 180,
    category: columnCategories.workflow,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'wf_updated_date',
  },
  {
    id: 'tcin',
    field: 'tcin',
    headerName: 'TCIN',
    width: 100,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'tcin',
  },
  {
    id: 'relationshipType',
    field: 'relationshipType',
    headerName: 'Relationship Type',
    width: 150,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'relationship_type',
  },
  {
    id: 'dpci',
    field: 'dpci',
    headerName: 'DPCI',
    width: 120,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'dpci',
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Description',
    width: 200,
    category: columnCategories.item,
    defaultIn: [],
    sort_disabled: true,
  },
  {
    id: 'launchDate',
    field: 'launchDate',
    headerName: 'Launch Date',
    width: 110,
    category: columnCategories.item,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'launch_date',
  },
  {
    id: 'parentTcin',
    field: 'parentTcin',
    headerName: 'Parent TCIN',
    width: 100,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'parent_tcin',
  },
  {
    id: 'class',
    field: 'class',
    headerName: 'Class ID/Name',
    width: 150,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'class_id',
  },
  {
    id: 'department',
    field: 'department',
    headerName: 'Department ID/Name',
    width: 150,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'department_id',
  },
  {
    id: 'barcode',
    field: 'barcode',
    headerName: 'Barcode',
    width: 130,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'barcode',
  },
  {
    id: 'vendor',
    field: 'vendor',
    headerName: 'Vendor ID',
    width: 100,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'vendor_id',
  },
  {
    id: 'manufacturerStyle',
    field: 'manufacturerStyle',
    headerName: 'Manufacturer Style',
    width: 150,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'manufacturer_style',
  },
  {
    id: 'size',
    field: 'size',
    headerName: 'Size',
    width: 80,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'size',
  },
  {
    id: 'color',
    field: 'color',
    headerName: 'Color',
    width: 80,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'color',
  },
  {
    id: 'brands',
    field: 'brands',
    headerName: 'Brands',
    width: 130,
    category: columnCategories.item,
    defaultIn: Object.values(workflowTabs),
    sort_name: 'brand_name',
  },
  {
    id: 'lifecycle',
    field: 'lifecycle',
    headerName: 'Lifecycle State',
    width: 180,
    category: columnCategories.item,
    defaultIn: [],
    sort_name: 'lifecycle_state',
  },
]

export const customizeColumns = {
  fileName: {
    name: 'Image Name',
    selected: false,
    category: 'item',
    disabled: true,
    default: true,
  },
  fileSize: {
    name: 'File Size',
    selected: false,
    category: 'item',
    disabled: true,
    default: false,
  },
  version: {
    name: 'Version',
    category: 'excluded',
    selected: true,
    disabled: true,
    default: true,
  },
  previewImage: {
    name: 'Image',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  ingestionDate: {
    name: 'Ingestion Date',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  subjectCodes: {
    name: 'Multi TCIN',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  description: {
    name: 'Description',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  photoStudio: {
    name: 'Photo Studio',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  photoShotDate: {
    name: 'Photo Shote Date',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  tbay: {
    name: 'TBay',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  photographer: {
    name: 'Photographer',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  stylist: {
    name: 'Stylist',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  altPosition: {
    name: 'Alt Position',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  sentToPipeline: {
    name: 'Sent To Pipeline',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  projects: {
    name: 'Projects',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  imageStatus: {
    name: 'Image Status',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  brands: {
    name: 'Brands',
    selected: false,
    category: 'item',
    disabled: false,
    default: true,
  },
  tags: {
    name: 'Tags',
    selected: false,
    disabled: false,
    default: true,
  },
  notes: {
    name: 'Notes',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  qaNotes: {
    name: 'QA Notes',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  assignee: {
    name: 'Assignee',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  qaAssignee: {
    name: 'QA Assignee',
    selected: false,
    category: 'item',
    disabled: false,
    default: false,
  },
  wip: {
    name: 'WIP',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  tcin: {
    name: 'TCIN',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  relationshipType: {
    name: 'Relationship Type',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  dpci: {
    name: 'DPCI',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  launchDate: {
    name: 'Launch Date',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  lastUpdatedDate: {
    name: 'Last Updated',
    selected: false,
    category: 'sample',
    disabled: false,
    default: false,
  },
  parentTcin: {
    name: 'Parent TCIN',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: true,
  },
  class: {
    name: 'Class ID/Name',
    selected: false,
    category: 'photo',
    disabled: false,
    default: false,
  },
  department: {
    name: 'Department ID/Name',
    selected: false,
    category: 'photo',
    disabled: false,
    default: false,
  },
  barcode: {
    name: 'Barcode',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  vendor: {
    name: 'Vendor ID',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  manufacturerStyle: {
    name: 'Manufacturer Style',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: true,
  },
  size: {
    name: 'Size',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  color: {
    name: 'Color',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
  lifecycle: {
    name: 'Lifecycle State',
    selected: false,
    category: 'photo_each',
    disabled: false,
    default: false,
  },
}

export const generateDefaultColumnVisibilityModel = (tabName) => {
  const columnVisibilityModel = cloneDeep(customizeColumns)
  workflowColumns.forEach((column) =>
    column.defaultIn?.indexOf(tabName) >= 0
      ? (columnVisibilityModel[column.id].selected = true)
      : (columnVisibilityModel[column.id].selected = false)
  )
  return columnVisibilityModel
}

export const generateCustomColumnVisibilityModel = (columnList, tabName) => {
  if (columnList && columnList.length > 0) {
    const columnVisibilityModel = cloneDeep(customizeColumns)
    workflowColumns.forEach((column) => {
      if (columnList.includes(column.id)) {
        columnVisibilityModel[column.id].selected = true
      }
    })
    return columnVisibilityModel
  } else {
    return generateDefaultColumnVisibilityModel(tabName)
  }
}

export const workflowFilterOptions = {
  NEW: [
    {
      id: 'ingested',
      label: 'Photo Shot & Ingested',
    },
    {
      id: 'rejected',
      label: 'Reshoot',
    },
    {
      id: 'allNew',
      label: 'All New',
    },
  ],
  RETOUCH: [
    {
      id: 'assignedRetouch',
      label: 'Assigned to Me',
    },
    {
      id: 'ready',
      label: 'Ready for Retouching',
    },
    {
      id: 'retouchingComplete',
      label: 'Retouching Complete',
    },
    {
      id: 'qaComplete',
      label: 'QA Complete',
    },
    {
      id: 'allRetouch',
      label: 'All Retouch',
    },
  ],
  COLOR: [
    {
      id: 'colorCorrection',
      label: 'Color Correction',
    },
    {
      id: 'allColor',
      label: 'All Color',
    },
  ],
  HOLD: [
    {
      id: 'hold',
      label: 'Hold',
    },
  ],
  REVIEW: [
    {
      id: 'assignedReview',
      label: 'Assigned to Me',
    },
    {
      id: 'readyForReview',
      label: 'Ready for Review',
    },
    {
      id: 'rejectedReview',
      label: 'Rejected by Pipeline',
    },
    {
      id: 'allReview',
      label: 'All Review',
    },
  ],
  APPROVED: [
    {
      id: 'approvedByStudio',
      label: 'Approved by Studio',
    },
    {
      id: 'approvedByRetouch',
      label: 'Approved by TII Retouch',
    },
    {
      id: 'allApproved',
      label: 'All Approved',
    },
  ],
}
